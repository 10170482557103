import {
  CallToBooking,
  Hero,
  Location,
  Values,
  Stores,
  Reviews,
  FourRooms,
  Divider,
} from '@components';

import { NextSeo } from 'next-seo';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { stringify } from 'qs';
import { useMediaQueries } from '@hooks';
import { DefaultLayout } from '@layouts';

export default function Home({ data, stores, locations }) {
  const {
    seo,
    features,
    parallax,
    locations: locationsHeader,
    stores: storesHeader,
    reviews,
    booking,
    fouRooms,
  } = data;

  const { isMobile } = useMediaQueries();

  return (
    <>
      <NextSeo
        title={`Momento | ${seo?.metaTitle}`}
        description={seo?.metaDescription}
      />
      <DefaultLayout
        header={
          <>
            <Hero
              showDescription={!isMobile}
              hasSlider={stores?.length > 0}
              slides={stores.map(({ attributes }) => {
                const {
                  name: title,
                  city,
                  description,
                  img,
                  cta,
                } = attributes;

                return {
                  title,
                  description,
                  label: city?.data?.attributes?.name,
                  img,
                  cta: cta && {
                    label: attributes?.cta?.label,
                    href: attributes?.cta?.href,
                    externalLink: attributes?.cta?.externalLink,
                  },
                };
              })}
            />
          </>
        }
      >
        <Values {...features?.header} list={features?.list} />
        <Divider {...parallax} />
        <Location {...locationsHeader} list={locations} />
        <Stores {...storesHeader} list={stores} />
        <Reviews
          {...reviews?.header}
          list={reviews?.list}
          img={reviews?.img}
        />

        <CallToBooking {...booking?.header} ctas={booking?.ctas} />
        <FourRooms
          {...fouRooms?.header}
          rooms={fouRooms?.rooms?.data}
          cta={fouRooms?.cta}
          img={fouRooms?.img}
        />
      </DefaultLayout>
    </>
  );
}

export async function getServerSideProps({ res, locale }) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59',
  );

  const path = process.env.NEXT_PUBLIC_STRAPI;

  const populateHome = stringify(
    {
      populate: [
        'features',
        'features.header',
        'features.list',
        'parallax',
        'parallax.img',
        'locations',
        'stores',
        'reviews',
        'reviews.header',
        'reviews.list',
        'reviews.list.store',
        'reviews.img',
        'booking',
        'booking.header',
        'booking.ctas',
        'fouRooms',
        'fouRooms.cta',
        'fouRooms.rooms',
        'fouRooms.img',
        'fouRooms.header',
        'seo',
        'img.data',
      ],
      locale,
    },
    { encodeValuesOnly: true },
  );

  const populateStores = stringify(
    {
      populate: [
        'location',
        'location.data',
        'city',
        'city.data',
        'cta',
        'img',
      ],
      locale,
    },
    { encodeValuesOnly: true },
  );

  const populateLocations = stringify(
    { populate: ['stores', 'stores.data'], locale },
    { encodeValuesOnly: true },
  );

  // HomePage Data
  const getHomePage = await fetch(
    `${path}/home-page?populate=${populateHome}`,
  );
  const homePage = await getHomePage?.json();

  // Stores data
  const getStores = await fetch(
    `${path}/stores?sort=slug&populate=${populateStores}`,
  );
  const getStoresData = await getStores?.json();

  // Locations data
  const getLocations = await fetch(
    `${path}/locations?sort=slug&populate=${populateLocations}`,
  );
  const getLocationsData = await getLocations?.json();

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      data: homePage?.data?.attributes || {},
      stores: [...getStoresData?.data],
      locations: [...getLocationsData?.data],
    },
  };
}
